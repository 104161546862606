<template>
  <page-main title="Upgrade to Resumation Plus!" :noBottom="true">
    <template v-slot:aside>
      Resumation Plus additional features include:
      <ol>
        <li>
          <b>Additional Access to AI features: </b> Resumation Plus will open your access to use generative artificial intelligence (AI) 
          features throughout the tool as you need it. Use AI features such as 'Optimize with AI' to transform your resume content and take your resume impact to the next
          level!
        </li>
        <li>
          <b>Create Customized Resumes:</b> You will be able to toggle the order
          and visibility of several different sections of your resume. Once you
          are completed, you can save the results and download in both Word and
          PDF formats!
        </li>
        <li>
          <b>New Features Betas:</b> Help us test out new products! We will add
          in betas for new features as they come - we value your feedback!
        </li>
        <li>
          <b>Need to apply to multiple jobs?:</b> No worries, Resumation Plus
          will allow you to save resumes and store them for you to use!
        </li>
      </ol>
    </template>
    <template v-slot:nav>
      <v-btn class="mt-8" color="primary" block @click="redirectToCheckout()"
        >Click to Upgrade to Resumation Plus!</v-btn
      >
    </template>
    <v-container>
      <v-btn color="primary" outlined block @click="redirectToCheckout()">{{
        upgradeButtonText
      }}</v-btn>
      <slot name="upgradeButton"></slot>
      <v-simple-table class="text-center features my-6" dense>
        <thead>
          <tr class="font-weight-bold">
            <th :class="titlesClasses">Pricing</th>
            <th :class="titlesClasses">Free</th>
            <th :class="titlesClasses">$5 per Month</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td :class="subtitlesClasses">Features</td>
            <td :class="subtitlesClasses">Resumation (Free Version)</td>
            <td :class="subtitlesClasses">Resumation (Paid Version)</td>
          </tr>
          <tr v-for="item in items" :key="item.name">
            <td>{{ item.name }}</td>
            <td :class="item.free ? 'available' : 'not-available'">
              <v-icon>{{ item.free ? "mdi-check" : "mdi-close" }}</v-icon>
            </td>
            <td :class="item.premium ? 'available' : 'not-available'">
              <v-icon>{{ item.premium ? "mdi-check" : "mdi-close" }}</v-icon>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <v-btn color="primary" outlined block @click="redirectToCheckout()">{{
        upgradeButtonText
      }}</v-btn>
    </v-container>
  </page-main>
</template>

<script>
export default {
  name: "Upgrade",
  components: {},
  data: () => ({
    titlesClasses: ["text-subtitle-1", "font-weight-bold", "text-center"],
    subtitlesClasses: ["text-subtitle-2", "font-weight-medium", "text-center"],
    upgradeButtonText: "Upgrade today to gain access to advanced features!",
    items: [
      {
        name: "Resume Standard Input Pages (with edit and auto-save capability)",
        free: true,
        premium: true,
      },
      {
        name: "Resume Review",
        free: true,
        premium: true,
      },
      {
        name: "Standard Resume Formats (Professional, Student/Early Career, and Plain Text)",
        free: true,
        premium: true,
      },
      {
        name: "Download Resume in Word and/or PDF Format",
        free: true,
        premium: true,
      },
      {
        name: "“Optimize with AI” Enabled",
        free: false,
        premium: true,
      },
      {
        name: "Create Customized Resumes",
        free: false,
        premium: true,
      },
      {
        name: "Save and Store Resumes",
        free: false,
        premium: true,
      },
    ],
  }),
  methods: {
    redirectToCheckout() {
      window.location.href = "/api/PaymentGateway";
    },
  },
  computed: {},
};
</script>

<style>
.container-main ol > li {
  margin: 16px 0;
}

.container-main ol > li::marker {
  font-weight: bold;
}

.features {
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.features thead th:not(:last-child),
.features tbody td:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.available {
  background-color: #c6efce;
}

.available i {
  color: #106952;
}

.not-available {
  background-color: #ffc7ce;
}

.not-available i {
  color: #a80c1e;
}
</style>
